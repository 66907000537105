<template>
  <div>
    <NuxtLayout
      name="teasers"
      v-bind="{ loading }"
      :no-results="!teasers.length"
      @refresh="refetch"
    >
      <LotteryTeaser
        v-for="(teaser, index) in teasers"
        :key="index"
        v-bind="{ ...teaser, licensedTerritory }"
        @update-teasers="refetch"
      />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
type PlayNowLotteriesProps = {
  pollInterval?: number
}

const props = withDefaults(defineProps<PlayNowLotteriesProps>(), {
  pollInterval: 3_000_000,
})

const licensedTerritory = useLicensedTerritory({ raw: true })

const { loading, refetch, result } = useLotteryTeasersQuery(
  { licensedTerritory },
  {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: props.pollInterval,
  },
)

const teasers = computed(() => result.value?.lotteryTeasers || [])

const { onResult } = useDrawDateChangesSubscription({ licensedTerritory })
onResult(() => refetch())
</script>
